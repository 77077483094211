<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px">
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">当前：供应商信息</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="9">
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini"
                                           @click="addEvent">添加供应商
                                </el-button>
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini"
                                           @click="banBatchEvent">激活/禁用供应商
                                </el-button>
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini"
                                           @click="removeBatchEvent">删除供应商
                                </el-button>
                                <el-button style=" margin-top: 8px; " id="createBill" type="primary" round size="mini"
                                           @click="getList">搜索查询
                                </el-button>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <vxe-toolbar class="pull-left" style=" height: 42px; " import custom print export
                                             ref="xToolbar2">
                                    <template #tools>
                                        <vxe-button type="text" title="模板下载"
                                                    class="vxe-button type--button el-icon-notebook-2 is--circle"
                                                    @click="downFileEvent" style=" margin-right: 12px; "></vxe-button>
                                    </template>

                                </vxe-toolbar>
                            </el-col>

                        </el-row>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid ref="xGrid"
                                  size="mini"
                                  :data="tableDataMain"
                                  :import-config="importConfig"
                                  height="650"
                                  border
                                  resizable
                                  show-overflow
                                  keep-source
                                  highlight-current-row
                                  :columns="tableColumns"
                                  :mouse-config="{selected: false}">
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="tablePage.pageSizes"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                            <template #operate="{ row}">
                                <template>
                                    <vxe-button icon="el-icon-edit-outline" title="编辑" circle
                                                @click="editRowEvent(row)"></vxe-button>
                                </template>
                                <template>
                                    <vxe-button icon="el-icon-connection" title="编辑同步UUID" circle
                                                status="primary"
                                                @click="editUUIDEvent(row)"></vxe-button>
                                </template>
                                <vxe-button icon="el-icon-delete" title="删除" circle
                                            @click="removeRowEvent(row)"></vxe-button>
                            </template>
                        </vxe-grid>
                    </el-col>
                </el-row>
            </el-form>
        </el-main>
        <el-dialog width="1220px" :title="isErrorTitle ? '失败' : '导入数据'" :visible.sync="dialogFormVisible"
                   :close-on-click-modal="false">
            <vxe-grid
                    border
                    resizable
                    height="400"
                    ref="xGridImport"
                    :columns="tableImport"
                    :data="tableDataImport"
                    :edit-config="{trigger: 'click', mode: 'cell', icon: 'fa fa-pencil-square-o'}">
            </vxe-grid>
            <div slot="footer" class="dialog-footer">
                <template v-if="isErrorTitle">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                </template>

                <el-button type="primary" @click="saveImport" v-show="!isErrorTitle">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog width="780px" :title="isAdd ?  '新增供应商信息' : '编辑供应商信息'" :visible.sync="showEdit"
                   :close-on-click-modal="false">
            <el-form ref="supplierForm" :rules="supplierFormRules" :model="supplierForm" label-width="100px">
                <el-row>
                    <el-col :lg="12" :md="12">
                        <el-form-item label="供应商名称" prop="supplier_name">
                            <el-input v-model="supplierForm.supplier_name" size="mini" style="width: 250px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="12" :md="12">
                        <el-form-item label="备注" prop="remark">
                            <el-input v-model="supplierForm.remark" size="mini" style="width: 250px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="12" :md="12">
                        <el-form-item label="联系人" prop="linkman">
                            <el-input v-model="supplierForm.linkman" size="mini" style="width: 250px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="12" :md="12">
                        <el-form-item label="联系人电话" prop="linkman_phone">
                            <el-input v-model="supplierForm.linkman_phone" size="mini"
                                      style="width: 250px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-col :md="24">
                    <el-form-item label="曾用名" prop="usedName">
                        <el-input style="width: 100%" v-model="supplierForm.used_name" readonly size="mini"></el-input>
                    </el-form-item>
                </el-col>
                <el-row>
                    <el-col :lg="12" :md="12">
                        <el-form-item label="状态">
                            <el-switch active-value="激活" inactive-value="禁用" v-model="supplierForm.state" style="width: 250px"></el-switch>
                        </el-form-item>
                        <el-form-item label="启动每天限价" label-width="160">
                            <el-switch active-value="开启" inactive-value="关闭" v-model="supplierForm.limited_price"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showEdit = false">取 消</el-button>
                <el-button type="primary" @click="addRoUpdateCustomerEditEvent">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog width="780px" title="与客户同步关联" :visible.sync="showIsCustomer"
                   :close-on-click-modal="false">
            <el-form :model="changeUuidForm" label-width="100px">
                <el-row>
                    <el-col :lg="12" :md="12">
                        <el-form-item label="供应商名称" prop="supplier_name">
                            <el-input v-model="changeUuidForm.supplier_name" readonly size="mini" style="width: 250px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="12" :md="12">
                        <el-form-item label="关联客户:">
                            <el-select ref="customer" v-model="changeUuidForm.customer_name" style="width: 100%"
                                       default-first-option remote :remote-method="$_searchCustomerList" clearable
                                       @change="changeUuidForm.customer_name = $event.customer_name,changeUuidForm.customer_uuid = $event.company_uuid"
                                       filterable size="mini" placeholder="可搜索查询">
                                <el-option
                                        v-for="item in $store.getters.getCustomerList"
                                        :key="item.id"
                                        :label="item.customer_name"
                                        :value="item">
                                    <span style="float: left">{{item.customer_name}}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showIsCustomer = false">取 消</el-button>
                <el-button type="primary" @click="changeUuidEvent">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import {filterRender} from "@/utils/gird-filter";


    export default {

        data() {
            return {
                showEdit: false,
                showIsCustomer: false,
                isAdd: false,
                uuid: '',
                isErrorTitle: '',
                tableImport: [
                    {field:'supplier_name', width: 250, title: '供应商名称'},
                    {field:'linkman', width: 120, title: '联系人'},
                    {field:'linkman_phone', width: 120, title: '联系电话'},
                    {field:'remark', width: 400, title: '备注'},
                    {
                        field:'error', width: 190, title: '#', slots: {
                            default: ({row}) => {
                                return [
                                    <el-link type="danger">{row.error}</el-link>
                                ]
                           },
                       }
                   }
                ],
                tableDataImport: [],
                importConfig: {
                    remote: true,
                    importMethod: this.importMethod,
                    modes: ['insert'],
                    type: ['xlsx'],
                    types: ['xlsx']
               },
                exportConfig: {type: ['xlsx'], types: ['xlsx']},
                border: true,
                resizable: true,
                keepSource: true,
                showOverflow: true,
                height: 650,
                loading: false,
                editConfig: {
                    // 设置触发编辑为手动模式
                    trigger: 'manual',
                    // 设置为整行编辑模式
                    mode: 'row',
                    // 显示修改状态和新增状态
                    showStatus: true,
                    // 自定义可编辑列头的图标
                    icon: 'fa fa-file-text-o'
               },
                tableColumns: [
                    {type: 'checkbox', width: 60},
                    {field:'operate', title: '操作', width: 120, slots: {default: 'operate'}},
                    //,visible:false
                    //
                    {
                        field:'company_uuid', width: 100, title: 'UUID', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/supplier/searchHeard')
                       }
                   },
                    {
                        field:'is_customer', width: 100, title: '已关联客户', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/supplier/searchHeard')
                        }
                    },
                    {
                        field:'id', width: 100, title: '供应商编号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/supplier/searchHeard')
                       }
                   },
                    {
                        field:'supplier_name', width: 320, title: '供应商名称', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/supplier/searchHeard')
                       }
                   },{
                        field:'used_name', width: 320, title: '曾用名', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/supplierStart/searchHeard')
                        }
                    },
                    {
                        field:'linkman', width: 120, title: '联系人', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/supplier/searchHeard')
                       }
                   },
                    {
                        field:'linkman_phone', width: 200, title: '联系电话', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/supplier/searchHeard')
                       }
                   },

                    {
                        field:'createData', width: 150, title: '建档日期', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {
                                size: 'mini',
                                type: 'date'
                           }, this.filterData, this.sortData, '/admin/supplier/searchHeard')
                       }
                   },
                    {
                        field:'remark', width: 100, title: '备注', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/supplier/searchHeard')
                       }
                   }, {
                        field:'state', width: 100, title: '状态', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/supplier/searchHeard')
                       }
                   }, {
                        field:'limited_price', width: 100, title: '每天限价', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/supplier/searchHeard')
                       }
                   },

                ],
                tableDataMain: [],

                dialogFormVisible: false,
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                    pageSizes: [ 500,1000,2500,5000]
               },
                filterData: {},
                sortData: {},
                changeUuidForm:{
                    id: '',
                    company_uuid: '',
                    supplier_name: '',
                    customer_uuid:'',//将要成为的客户uuid
                    customer_name:'',//将要成为的客户名称
                },
                supplierForm: {
                    id: '',
                    supplier_name: '',
                    linkman: '',
                    linkman_phone: '',
                    remark: '',
                    used_name: '',
                    state: '激活',
                    limited_price: false,
               },
                supplierFormRules: {
                    supplier_name: [
                        {required: true, message: '请选输入供应商名称', trigger: 'blur'}
                    ],
                    linkman: [
                        {required: true, message: '请选输入联系人', trigger: 'blur'}
                    ],
                    linkman_phone: [
                        {required: true, message: '请选输入联系人电话', trigger: 'blur'}
                    ],
                    remark: [
                        {required: false, message: '请选输入备注', trigger: 'blur'}
                    ],

               },
                activeName: 'first',
                remvoeIds: [],
                banIds: [],
                changeId: '',
                account: [],
           }
       },
        methods: {
            editRowEvent(row) {
                this.$axios({
                    method: 'post',
                    url: '/admin/supplier/editVue',
                    params: {
                        id: row.id
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.showEdit = true;
                        this.isAdd = false;
                        this.supplierForm.supplier_name = response.data.supplier_name;
                        this.supplierForm.linkman = response.data.linkman;
                        this.supplierForm.phone = response.data.phone;
                        this.supplierForm.id = response.data.id;
                        this.supplierForm.linkman_phone = response.data.linkman_phone;
                        this.supplierForm.remark = response.data.remark;
                        this.supplierForm.state = response.data.state;
                        this.supplierForm.limited_price = response.data.limited_price;
                        this.supplierForm.used_name = response.data.used_name;
                   }

               }).catch((error) => {
                    console.log(error)
               });
           },
            editUUIDEvent(row) {
                this.showIsCustomer = true;
                this.changeUuidForm.company_uuid = row.company_uuid,
                this.changeUuidForm.supplier_name = row.supplier_name,
                this.changeUuidForm.id = row.id
            },
            changeUuidEvent(){
                this.showIsCustomer = false;
              //修改供应商uuid，与客户进行关联
                this.$XModal.confirm('将会同步客户UUID为供应商UUID，不可恢复！').then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        //传送删除动作
                        this.$axios({
                            method:'post',
                            url:'/admin/supplier/changeUuid',
                            data: {
                                changeUuidForm:this.changeUuidForm
                            }
                        }).then((response) =>{         //这里使用了ES6的语法
                            //(response)       //请求成功返回的数据
                            if(response.status === 200 && response.data.state == 'ok'){
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'success'
                                });
                                this.getList()
                            }else{
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                                });
                                return
                            }
                        }).catch((error) =>{
                            console.log(error)
                        });
                    }
                })
            },
            addRoUpdateCustomerEditEvent() {
                let url = '';
                if (this.isAdd) {
                    url = '/admin/supplier/saveVue';
               } else {
                    url = '/admin/supplier/updateVue';
               }
                this.$refs.supplierForm.validate((valid) => {
                    if (valid) {
                        this.$axios({
                            method: 'post',
                            url: url,
                            data: this.supplierForm,
                       }).then((response) => {          //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '保存成功',
                                    type: 'success'
                               });
                                this.showEdit = false;
                                this.getList();
                           } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) => {
                            console.log(error)
                       });
                   } else {
                        //console.log('error submit!!');
                        return false;
                   }
               });
           },
            addEvent() {
                this.showEdit = true
                this.isAdd = true
                this.supplierForm.id = '';
                this.supplierForm.supplier_name = '';
                this.supplierForm.linkman = '';
                this.supplierForm.linkman_phone = '';
                this.supplierForm.remark = '';
                this.supplierForm.state = '激活';
                this.supplierForm.used_name = '';
           },


            banBatchEvent() {
                const $grid = this.$refs.xGrid
                const selectRecords = $grid.getCheckboxRecords();
                this.banIds = [];
                for (let i = 0; i < selectRecords.length; i++) {
                    this.banIds.push(selectRecords[i].id)
               }
                if (selectRecords.length > 0) {
                    this.$XModal.confirm('您确定要激活/禁用 ' + this.banIds.length + ' 个供应商?').then(type => {
                        if (type === 'confirm') {
                            //传送删除动作
                            this.$axios({
                                method: 'post',
                                url: '/admin/supplier/banBatchVue',
                                params: {
                                    banIds: this.banIds
                               }
                           }).then((response) => {          //这里使用了ES6的语法
                                //(response)       //请求成功返回的数据
                                if (response.status === 200 && response.data.state == 'ok') {
                                    this.$message({
                                        showClose: true,
                                        message: '批量删除成功',
                                        type: 'success'
                                   });
                                    this.getList();
                               } else {
                                    this.$message({
                                        showClose: true,
                                        message: response.data.msg,
                                        type: 'error'
                                   });
                                    return
                               }
                           }).catch((error) => {
                                console.log(error)
                           });
                       }
                   });
               } else {
                    this.$message({
                        showClose: true,
                        message: "请先选择",
                        type: 'error'
                   });
               }

           },
            removeBatchEvent() {
                const $grid = this.$refs.xGrid
                const selectRecords = $grid.getCheckboxRecords();
                this.remvoeIds = [];
                for (let i = 0; i < selectRecords.length; i++) {
                    this.remvoeIds.push(selectRecords[i].id)
               }
                if (selectRecords.length > 0) {
                    this.$XModal.confirm('您确定要删除 ' + this.remvoeIds.length + ' 条数据?').then(type => {
                        if (type === 'confirm') {
                            //传送删除动作
                            this.$axios({
                                method: 'post',
                                url: '/admin/supplier/deleteBatchVue',
                                params: {
                                    remvoeIds: this.remvoeIds
                               }
                           }).then((response) => {          //这里使用了ES6的语法
                                //(response)       //请求成功返回的数据
                                if (response.status === 200 && response.data.state == 'ok') {
                                    this.$message({
                                        showClose: true,
                                        message: '批量删除成功',
                                        type: 'success'
                                   });
                                    this.getList();
                               } else {
                                    this.$message({
                                        showClose: true,
                                        message: response.data.msg,
                                        type: 'error'
                                   });
                                    return
                               }
                           }).catch((error) => {
                                console.log(error)
                           });
                       }
                   });
               } else {
                    this.$message({
                        showClose: true,
                        message: "请先选择",
                        type: 'error'
                   });
               }

           },
            removeRowEvent(row) {
                this.$XModal.confirm('您确定要删除该数据?').then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        //传送删除动作
                        this.$axios({
                            method: 'post',
                            url: '/admin/supplier/deleteVue',
                            params: {
                                id: row.id
                           }
                       }).then((response) => {          //这里使用了ES6的语法
                            //(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '删除成功',
                                    type: 'success'
                               });
                                this.getList();
                           } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) => {
                            console.log(error)
                       });

                   }
               })
           },
            getList() {
                this.$axios({
                    method: 'POST',
                    url: '/admin/supplier',
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        sortData: this.sortData,
                        filterData: this.filterData,
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            saveImport() {
                this.$axios({
                    method: 'post',
                    url: '/admin/supplier/saveImportExcel',
                    params: {
                        uuid: this.uuid
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '导入成功',
                            type: 'success'
                       });
                        this.dialogFormVisible = false
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
                    this.getList();
               }).catch((error) => {
                    console.log(error)
               });
           },
            downFileEvent() {
                window.open('/template/supplier.xlsx', '_blank')
           },
            importMethod({file}) {
                const loading = this.$loading({
                    lock: true,
                    text: '导入中，请喝杯水，耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                const formBody = new FormData()
                formBody.append('file', file)
                this.$axios({
                    method: 'post',
                    url: '/admin/supplier/importExcel',
                    data: formBody,
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.isErrorTitle = response.data.isError
                        if (response.data.isError == true) {
                            this.tableDataImport = response.data.supplierListError
                       } else {
                            this.tableDataImport = response.data.supplierList

                       }
                        this.uuid = response.data.uuid
                        this.dialogFormVisible = true;
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getList()
           },

            saveRowEvent() {
                const $grid = this.$refs.xGrid
                $grid.clearActived().then(() => {
                    this.loading = true
                    setTimeout(() => {
                        this.loading = false
                        this.$XModal.message({content: '保存成功！', status: 'success'})
                   }, 300)
               })
           },

       },
        created() {

            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                this.getList()
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    /deep/ .el-form-item__error {
        position: initial !important;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }

    /deep/ .vxe-input--panel {
        position: fixed !important;
        left: auto;
   }

    /deep/ .el-input-number .el-input__inner {
        text-align: initial !important;
   }

    .el-form-item__content .el-input-group {
        vertical-align: initial;
   }
</style>
